@import "colors.bpc5.css";

.App {
  text-align: left;
  min-height: 100vh;
  background-color: black;
  
}

.Body {
    max-width: 100vw ;
    
  }


.pageContent {
  text-align: center;
  padding-top: 50px;
}


.pageContentSmall {
  text-align: center;
  font-size: 28px;
    
}

.pageContentMed2 {
  text-align: center;
  font-size: calc(12px + 2vw);
    
}

.pageContentTitle {
  text-align: center;
  font-size: calc(35px + 2vw);
  background-color:rgb(0, 0, 0);
  border-radius:50px;
  
}

.helpContent {
  text-align: center;
  font-weight: normal;
  font-size: 14px;
}

.helpContentHedder {
  text-align: center;
  font-weight: normal;
  font-size: 20px;
}


.tosContentMedBold {
  text-align: center;
  font-size: 23px;
  font-weight: bold;
}

.tosContentBold {
  text-align: center;
  font-weight: bold;
}


.tosContentSmall2 {
  text-align: left;
}



.FAQContentBold {
  font-weight: bold;
  font-size: 20px;
}

.FAQContent {
  font-weight: normal;
  font-size: 18px;
}

.AboutContent {
  font-weight: normal;
  font-size: 18px;
}




.navBarLink {
	font-size: calc(18px );
  padding-left: 4px;
  padding-bottom: 4px;
}

.navBarLinkActive {
  font-size: calc(18px );
  padding-left: 4px;
  padding-bottom: 4px;
}


.navBarTitle {
  
  font-size: calc(18px); 
  margin-left: 20px;
}

.navBarEnd {
  
  margin-right: 20px;
  
  
  font-size: calc(18px); 
}

.navBarBox {
  position : fixed;
  top :0;
  min-width: 100vw;
 z-index: 4;
}

