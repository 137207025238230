.App {
  background-color: #000000;
}




.Top-title {
  color: rgb(255, 255, 255) ;
}



.navBarLink {
  color: #ffffff;
  
}

.navBarLinkActive {
  color: #fcef42; 
  
}

.navBarTitle {
  color:#ffffff;
  
}

 .navBarBox {
    background-color:  #000000;
  }

.App-link-button {
  background-color: #ecdd02;
  color: rgb(0, 0, 0);
}

.pageContent {
  color: #e0e0e0;
}

.FAQContent {
  color: #e0e0e0;
}

.AboutContent {
  color: #e0e0e0;
}

.FAQContentBold {
  color: red;
}

.pageContentMed2 {
  color: rgb(255, 255, 255);
}

.pageContentHedder {
  color: #FB8B24 ;
}



.imageBorder {
  color: white;
}


.tosContentSmall2 {
  color: #e0e0e0;;
}

.tosContentMedBold {
  color: #1f87a1;
}

.tosContentBold {
  color: #1f87a1;
}

.tosContentTitle {
  color: #ca0c2f;
}

.pageContentMed2 {
  color: white;
}

.helpContentHedder {
  color: red;
}

.helpContentTitle {
  color: #ff002f;
}


.helpContent {
  color: #e0e0e0;

}











.Misc-button {
  background-color: #0F4C5C;
  color: white ;
}

.SO-button {
  background-color:  #9A031E;
  color: white;
}


.RB-button {
  background-color: #FB8B24;
  color: white;
}


.SP-button {
  background-color: #ce5a12;
  color: white;
}




